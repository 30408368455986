/*-----------------------------------*/
/*--- IMPORT STYLES FOR FASTPAGES ---*/
@import "minima/fastpages-styles";   



/*-----------------------------------*/
/*----- ADD YOUR STYLES BELOW -------*/

// If you want to turn off dark background for syntax highlighting, comment or delete the below line.
@import "minima/fastpages-dracula-highlight"; 

// Add your styles below
.site-title-avatar .avatar-img {
    width: 45px;
    height: 45px;
    margin: 4.5px 5px 4.5px 0;
    border-radius: 50%;
    float: left
}